import Vue from 'vue'
import App from './App.vue'
import './api/index'
import './importUi/index'
import router from './router/index'
import TRTC from 'trtc-js-sdk';
import '@/utils/aegis.js';
import { isMobile } from '@/utils/utils';

Vue.prototype.$isMobile = isMobile;
TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.DEBUG);

Vue.config.productionTip = false
new Vue({
	render: h => h(App),
	router
}).$mount('#app')