import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
// 请求拦截器
axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        let token = localStorage.getItem('token');
        if (token) {
          // 添加headers
          config.headers['token'] = token // token
        }      
        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
);

const baseUrl = 'https://gj.xp.yn.cn/api'
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(baseUrl + url, {
            params: params
        }).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            console.log('连接错误请求失败', err)
            reject(err.data)
        })
    })
}

export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(baseUrl + url, data)
        .then((res) => {
            resolve(res.data)
        }).catch((err) => {
            console.log('连接错误请求失败', err)
            reject(err)
        })
    })
}