import Vue from 'vue'
import { 
    Scrollbar,
    Input,
    Button,
    Alert,
    DatePicker,
    TimePicker,
    TimeSelect,
    MessageBox,
    Pagination,
    Upload,
    Dialog,
    Form,
    FormItem,
    InputNumber,
    CheckboxGroup,
    Checkbox,
    Message,
    Dropdown,
    DropdownItem,
    DropdownMenu
} from 'element-ui'
Vue.use(Scrollbar)
Vue.use(Input)
Vue.use(Button)
Vue.use(Alert)
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Pagination);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(InputNumber);
Vue.use(CheckboxGroup);
Vue.use(Checkbox); 
Vue.use(Dropdown);
Vue.use(DropdownMenu); 
Vue.use(DropdownItem); 
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$message = Message;


