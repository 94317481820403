<template>
    <div id="app">
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </div>
</template>

<script>
import autofit from 'autofit.js';
export default {
    name: 'App',
    data() {
      return {
        transitionName: ''
      }
    },
    mounted() {
      autofit.init({
        designHeight: 1080,
        designWidth: 1920,
        renderDom:"#app",
        resize: true
      })
      this.$aegis.reportEvent({
        name: 'loaded',
        ext1: 'loaded-success',
        ext2: 'webrtcQuickDemoVue2',
      });
    }
}
</script>

<style>
	body{
		margin: 0;
    /* background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(82,0,5,0.96) 100%); */
	}
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #16203D;
  }
  body {
    margin: 0;
    padding: 0;
    user-select: none;
  }
  @font-face{
    font-family: 'YouSheBiaoTiHei';
    src : url('@/assets/font/fontStyle.ttf');
  }
  /* @font-face{
    font-family: 'YouSheBiaoTiHei';
    src : url('@/assets/font/YouSheBiaoTiHeiBiaoTiTi-1.ttf');
  } */
  /* 隐藏谷歌 火狐，IE浏览器的滚动条 */
  *::-webkit-scrollbar {
    display: none;
  }
  /* * {
    scrollbar-width: none;
  }
  * {
    -ms-overflow-style: none;
  } */
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0.3;
}
.fade-leave, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0.3;
}
</style>
