import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)
const home = () => import('@/view/home') // 首页
const basicInfo = () => import('@/view/basicInfo') // 基本信息
const representInfo = () => import('@/view/representInfo')
const representDetail = () => import('@/view/representInfo/representDetail')
const representInfoDetail = () => import('@/view/representInfo/representInfoDetail')
const peopleSystem = () => import('@/view/peopleSystem')
const peopleSystemDetail = () => import('@/view/peopleSystem/peopleSystemDetail')
const noticeList = () => import('@/view/noticeList')
const noticeListDetail = () => import('@/view/noticeList/noticeListDetail')
const policyRules = () => import('@/view/policyRules')
const policyRulesDetail = () => import('@/view/policyRules/policyRulesDetail')
const resumeStyle = () => import('@/view/resumeStyle')
const resumeStyleDetail = () => import('@/view/resumeStyle/resumeStyleDetail')
const lineMeeting = () => import('@/view/lineMeeting')
const lineMeetingDetail = () => import('@/view/lineMeeting/lineMeetingDetail')
const createMeeting = () => import('@/view/lineMeeting/createMeeting')
const workInfoDetail = () => import('@/view/basicInfo/workInfoDetail')
const routes = [
	{
		path: '/',
		redirect: 'home'
	},
	{
		path: '/home',
		component: home
	},
	{
		path: '/basicInfo',
		component: basicInfo
	},
	{
		path: '/representInfo',
		component: representInfo
	},
	{
		path: '/representDetail',
		component: representDetail
	},
	{
		path: '/representInfoDetail',
		component: representInfoDetail
	},
	{
		path: '/peopleSystem',
		component: peopleSystem
	},
	{
		path: '/peopleSystemDetail',
		component: peopleSystemDetail,

	},
	{
		path: '/noticeList',
		component: noticeList,
	},
	{
		path: '/noticeListDetail',
		component: noticeListDetail,
	},
	{
		path: '/policyRules',
		component: policyRules,
	},
	{
		path: '/policyRulesDetail',
		component: policyRulesDetail,
	},
	{
		path: '/resumeStyle',
		component: resumeStyle,
	},
	{
		path: '/resumeStyleDetail',
		component: resumeStyleDetail,
	},
	{
		path: '/lineMeeting',
		component: lineMeeting,
	},
	{
		path: '/createMeeting',
		component: createMeeting,
	},
	{
		path: '/lineMeetingDetail',
		component: lineMeetingDetail,
	},
	{
		path: '/workInfoDetail',
		component: workInfoDetail,
	}
]

const router = new VueRouter({
	routes
})

export default router
